import { render, staticRenderFns } from "./moreupload.vue?vue&type=template&id=998af3d0&scoped=true&"
import script from "./moreupload.vue?vue&type=script&lang=js&"
export * from "./moreupload.vue?vue&type=script&lang=js&"
import style0 from "./moreupload.vue?vue&type=style&index=0&id=998af3d0&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "998af3d0",
  null
  
)

export default component.exports