<template>
  <div class="container">
    <div class="same-container">
      <div class="size-container">
        <div class="public-container">
          <div
            class="item flex column bewteen"
            v-for="(item, index) in applicationList"
            :key="index"
            :style="
              isShowBg && itemIndex == index
                ? $bgImg('sbitem-activity.png')
                : $bgImg('sbitem-unactivity.png')
            "
            @mouseover="handleMouseOver(index)"
            @mouseleave="handleMouseLeave"
          >
            <div class="title">{{ item.title }}</div>
            <div class="Shelves flex">
              {{ item.row1 }}
              <div class="geshu">{{ item.row1data }}</div>
              个
            </div>
            <div class="Shelves1 flex">
              {{ item.row2 }}
              <div class="geshu">{{ item.row2data }}</div>
              个
            </div>
            <div class="btns flex">
              <el-button
                type="primary"
                class="pri-btn"
                @click="gotoList(item)"
                >{{ item.btnName }}</el-button
              >
            </div>
          </div>
          <!-- 分页 -->
          <!-- <Pagination
            class="pagination"
            :total="total"
            :currentPage="currentPage"
            :pageSize="+pageSize"
            :layout="'prev, pager, next'"
            @pageChange="getList"
            v-if="this.total > 6"
          >
          </Pagination> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { data } from "jquery";
import {
  getDeclarationCount,
  getcountCheckNumber,
  getDigitalNumber,
} from "@/api/zf";
export default {
  data() {
    return {
      applicationList: [
        {
          title: "数字化产品入库申报",
          row1: "已上架：",
          row1data: " ",
          row2: "待审批：",
          row2data: "3",
          btnName: "查看申报",
          type: 1,
        },
        {
          title: "试点意向企业入库申报",
          row1: "已入库：",
          row1data: "",
          row2: "待审批：",
          row2data: "3",
          btnName: "查看征集",
          type: 2,
        },
        {
          title: "数字化改造项目备案申报",
          row1: "已备案：",
          row1data: "",
          row2: "待审批：",
          row2data: "3",
          btnName: "查看申报",
          type: 3,
        },
      ],
      isShowBg: false,
      itemIndex: -1,

      // 分页
      total: 0,
      page: 1,
      currentPage: 1,
      pageSize: 6, // 默认10个
    };
  },
  methods: {
    getList() {},
    toIndex() {
      this.$router.push({
        path: "/government/sbzj",
      });
    },
    gotoList(item) {
      let path = "";
      if (item.type == 1) {
        path = "/government/sbzj/list";
      } else if (item.type == 2) {
        path = "/government/sbzj/listSecond";
      } else if (item.type == 3) {
        path = "/government/sbzj/listThird";
      }
      this.$router.push({
        path: path,
        query: {
          type: item.type,
        },
      });
    },
    toForm() {
      this.$router.push({
        path: "/government/sbzj/form",
      });
    },

    handleMouseOver(i) {
      this.isShowBg = true;
      this.itemIndex = i;
    },
    handleMouseLeave() {
      this.isShowBg = false;
    },

    getdata() {
      getDeclarationCount().then((res) => {
        console.log("res", res.data.auditCount);
        if (res.code == 1) {
          this.applicationList[0].row1data = res.data.auditCount;
          this.applicationList[0].row2data = res.data.requireCount;
          console.log("ressss", this.applicationList[0].row1data);
        } else {
          this.$message.error(res.msg);
        }
      });
      getcountCheckNumber().then((res) => {
        console.log("res2", res);
        if (res.code == 1) {
          this.applicationList[1].row1data = res.data.status2Count;
          this.applicationList[1].row2data = res.data.status1Count;
        } else {
          this.$message.error(res.msg);
        }
      });
      getDigitalNumber().then((res) => {
        console.log("res", res);
        if (res.code == 200) {
          this.applicationList[2].row1data = res.data.auditNeedCount;
          this.applicationList[2].row2data = res.data.auditSuccessCount;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {
    this.getdata();
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.container {
  background-color: #eff4fe;

  .same-container {
    width: 100%;
    height: 100%;
    background: #f0f5ff;
    background-size: 1920px 1000px;
    background-attachment: fixed;

    .size-container {
      width: 80%;
      height: 100%;
      margin: 0 auto;
      min-height: 700px;

      .public-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;

        .item {
          width: 32%;
          height: 318px;
          margin-right: 1%;
          margin-bottom: 1%;
          background-position: bottom;
          background-repeat: no-repeat;
          background-size: auto 100%;
          border-radius: 10px;
          padding: 50px 20px 30px;
          box-sizing: border-box;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:hover {
            .title {
              color: #205dc3;
            }
          }

          .title {
            font-weight: 600;
            line-height: 1.5;
            font-family: Microsoft YaHei;
            font-size: 18px;
            color: #333;
          }
          .Shelves {
            font-family: Microsoft YaHei;
            font-weight: 600;
            line-height: 1.5;
            color: #7c7c7c;
          }
          .Shelves1 {
            font-family: Microsoft YaHei;
            position: relative;
            line-height: 1.5;
            top: -20px;
            font-weight: 600;
            color: #7c7c7c;
          }
          .geshu {
            color: #205dc3;
            font-size: 22px;
            position: relative;
            top: -5px;
            margin-right: 5px;
          }
          .btns {
            justify-content: flex-end;
            .cancel-btn {
              background-color: #e2e2e2;
              color: #333;
              &:hover {
                background-color: #efefef;
                color: #333;
              }
            }
          }
        }

        .pagination-container {
          width: 100%;
        }
      }
    }
  }
}

::v-deep .el-pagination {
  width: 100%;
}
</style>
