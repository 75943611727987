import { MediaUrl } from "@/utils/imgUrl";
import configs from "@/config/index.js";

export const staticMedia = (value) => {
  if (!value) return "";
  value = value.toString();
  return MediaUrl.getImgUrl("DEFAULT", value);
};

export const contentMedia = (value) => {
  if (!value) return "";
  value = value.toString();
  if (value.indexOf("data:image") != -1) return value;
  if (value.indexOf(configs.netLink) != -1) {
    // if (value.indexOf(configs.netLink + ":999") == -1) {
    //   let val = value.replace(configs.netLink, configs.netLink + ":999");
    //   return val;
    // } else {
    return value;
    // }
  }
  return MediaUrl.getImgUrl("CONTENT", value);
};
